<template>
	<div>
		<!-- Filters -->
		<data-filters
			:city-filter.sync="filters.city"
			:sector-filter.sync="filters.sector"
			:doner-filter.sync="filters.donor"
			@apply="loadData"
		/>

		<b-card v-if="ready">
			<b-row>
				<b-col cols="4">
					<bar-chart title="Labors by residency" :height="150" :chart-data="chartData.barChart(labels, chart.laborsChartData)" />
				</b-col>
				<b-col md="4">
					<doughnut-chart
						title="Jobs by residency"
						:height="150"
						:chart-data="chartData.doughnutChart(labels, chart.jobsChartData)"
						:key="jobs.length"
					/>
				</b-col>
				<b-col md="4">
					<bar-chart
						title="Jobs VS Labors"
						:height="150"
						:chart-data="chartData.barChart(labels, bothChart)"
						:key="(jobs.length || 0) + (labors.length || 0)"
					/>
				</b-col>
			</b-row>
		</b-card>

		<b-tabs pills>
			<b-tab title="Labors">
				<base-table :items="labors" table-top paginate excel-file-name="beneficiaries-labors">
					<template #cell(expense)="{ value }">
						<strong>{{ formatMoney(value) }}</strong>
					</template>
				</base-table>
			</b-tab>
			<b-tab title="Jobs">
				<base-table :items="jobs" table-top paginate excel-file-name="beneficiaries-jobs">
					<template #cell(expense)="{ value }">
						<strong>{{ formatMoney(value) }}</strong>
					</template>
				</base-table>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BCard, BTabs, BTab, BRow, BCol } from 'bootstrap-vue';

import BarChart from '@/components/charts/BarChart';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';
import { formatMoney } from '@/utils/helpers';

import DataFilters from '../../../components/DataFilters.vue';
import chartData from '../chartjsData';

export default {
	components: {
		DataFilters,
		BCard,
		BTabs,
		BTab,
		BRow,
		BCol,

		BarChart,
		DoughnutChart,
		BaseTable,
	},

	data: () => ({
		ready: false,
		labors: [],
		jobs: [],

		filters: {
			donor: '',
			sector: '',
			city: '',
		},

		chartData,

		chart: {
			laborsChartData: [
				{
					data: [],
					label: 'Labors',
					backgroundColor: [
						'rgba(255, 205, 86, 0.2)',
						'rgba(75, 192, 192, 0.2)',
						'rgba(54, 162, 235, 0.2)',
						'rgba(153, 102, 255, 0.2)',
					],
					borderColor: ['rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
				},
			],
			jobsChartData: [],
		},

		labels: ['Residents', 'Displaced', 'Returned', 'Outsiders'],
	}),

	computed: {
		bothChart() {
			return [
				this.chart.laborsChartData[0],
				{
					data: this.chart.jobsChartData,
					label: 'Jobs',
					backgroundColor: [
						'rgba(255, 205, 86, 0.2)',
						'rgba(75, 192, 192, 0.2)',
						'rgba(54, 162, 235, 0.2)',
						'rgba(153, 102, 255, 0.2)',
					],
					borderColor: ['rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
				},
			];
		},
	},

	async created() {
		this.loadData();
	},

	methods: {
		formatMoney,

		async loadData() {
			const params = new URLSearchParams(this.getFilters());

			const labors = await api.call({ path: `m&e/labors?${params}`, method: 'GET' });
			const jobs = await api.call({ path: `m&e/jobs?${params}`, method: 'GET' });

			this.labors = labors.data;
			this.jobs = jobs.data;

			this.calcChartData();
			this.ready = true;
		},

		calcChartData() {
			this.chart.laborsChartData[0].data = [
				this.labors.map((item) => item.residentLabors).reduce((prev, cur) => prev + cur, 0),
				this.labors.map((item) => item.displacedLabors).reduce((prev, cur) => prev + cur, 0),
				this.labors.map((item) => item.returnedLabors).reduce((prev, cur) => prev + cur, 0),
				this.labors.map((item) => item.outsidersLabors).reduce((prev, cur) => prev + cur, 0),
			];

			this.chart.jobsChartData = [
				this.jobs.map((item) => item.residentJobs).reduce((prev, cur) => prev + cur, 0),
				this.jobs.map((item) => item.displacedJobs).reduce((prev, cur) => prev + cur, 0),
				this.jobs.map((item) => item.returnedJobs).reduce((prev, cur) => prev + cur, 0),
				this.jobs.map((item) => item.outsidersJobs).reduce((prev, cur) => prev + cur, 0),
			];
		},

		getFilters() {
			return {
				donor: this.filters.donor || '',
				sector: this.filters.sector || '',
				city: this.filters.city || '',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>
