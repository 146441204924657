<template>
	<div>
		<base-table table-top :headers="headers" :items="items" paginate excel-file-name="beneficiaries-female-laborers">
			<template #cell(cost)="{ value }">
				{{ formatMoney(value, true) }}
			</template>
		</base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';
import { formatMoney } from '@/utils/helpers';

export default {
	name: 'FemaleLaborers',

	components: { BaseTable },

	data: () => ({
		headers: [
			{ key: 'donor', label: 'Donor' },
			{ key: 'females', label: 'NO. of Labrorers - Females' },
			{ key: 'cost', label: 'AVG. Cost for Labrorers - Females' },
		],

		items: [],
	}),

	async created() {
		const { data } = await api.call({
			path: 'm&e/females',
			method: 'GET',
		});

		this.items = data;
	},

	methods: {
		formatMoney,
	},
};
</script>

<style lang="scss" scoped></style>
