<template>
	<div>
		<b-tabs pills>
			<b-tab title="Beneficiaries">
				<beneficiaries />
			</b-tab>

			<b-tab title="Donors females laborers">
				<female-laborers />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import FemaleLaborers from './FemaleLaborers.vue';
import Beneficiaries from './Beneficiaries.vue';

export default {
	components: { BTabs, BTab, FemaleLaborers, Beneficiaries },
};
</script>

<style lang="scss" scoped></style>
